import { Campaign } from "@models/Campaign/Campaign";
import React from "react";
import { Grid } from "semantic-ui-react";
import { ButtonWrapper, CampaignAdsLeftDescription, CampaignAdsRightDescription, CampaignAdsRightTitle, FooterRowWrapper, LeftColumnWrapper, LeftTitleLine1, LeftTitleLine2, OutsideColumnWrapper, RightColumnWrapper } from "./SweepstakeAds.styles";
import { convertKontentColorToHex } from "@utils/Helpers";
import moment from "moment";
import { Disclaimer, ImageWrapper, SubTitleRowWrapper, SubTitleWrapper } from "./SweepstakeCampaign.styles";
import { KpButton } from "@elements/index";

interface Props {
    campaign: Campaign,
    available: boolean
}

class SweepstakeAds extends React.Component<Props>{
    constructor(props:Props){
        super(props);
    }

    render() {
        const { ads, campaignPopup } = this.props.campaign;
        const left_background_colors = convertKontentColorToHex(ads.left_background_colors.value[0].codename);
        const right_background_colors = convertKontentColorToHex(ads.right_background_colors.value[0].codename);
        const expiredOn = moment(new Date(this.props.campaign.expiresOn.toString())).format("DD/MM/YYYY")

        return(            
            <OutsideColumnWrapper mobile={16} tablet={16} computer={16}>
                <Grid stackable stretched>   
                    <LeftColumnWrapper bgColor={left_background_colors} mobile={16} tablet={16} computer={8}>
                        <LeftTitleLine1>{ads.left_title_1}</LeftTitleLine1>
                        <LeftTitleLine2>{ads.left_title_2}</LeftTitleLine2>
                        <div>
                            <ImageWrapper src={ads.left_picture[0].url} alt={ads.left_picture[0].description}/> 
                            <CampaignAdsLeftDescription>{ads.left_description}</CampaignAdsLeftDescription>
                        </div>
                        {this.props.available && <ButtonWrapper>
                            <KpButton id="noId" fullWidth="mobile tablet" buttonType='primary' color="black" textColor="white">ENTER NOW</KpButton>
                        </ButtonWrapper> }                       
                    </LeftColumnWrapper>
                    <RightColumnWrapper bgColor={right_background_colors} mobile={16} tablet={16} computer={8}>
                        <CampaignAdsRightTitle>{ads.right_title}</CampaignAdsRightTitle>
                        <CampaignAdsRightDescription dangerouslySetInnerHTML={{ __html: ads.right_description_1}}></CampaignAdsRightDescription>  
                        <CampaignAdsRightDescription dangerouslySetInnerHTML={{ __html: ads.right_description_2}}></CampaignAdsRightDescription>
                        <CampaignAdsRightDescription dangerouslySetInnerHTML={{ __html: ads.right_description_3}}></CampaignAdsRightDescription>
                        <SubTitleRowWrapper>
                            <Grid.Column>
                                <SubTitleWrapper>
                                    {campaignPopup.footer} <span>{expiredOn}</span>
                                </SubTitleWrapper>
                            </Grid.Column>                                                                
                        </SubTitleRowWrapper>    
                        <FooterRowWrapper>
                            <Grid.Column>
                                <Disclaimer textAlign="center" color="#ddd" dangerouslySetInnerHTML={{ __html: campaignPopup.terms___conditions}} ></Disclaimer>
                            </Grid.Column>
                        </FooterRowWrapper>                       
                    </RightColumnWrapper>
                </Grid>
            </OutsideColumnWrapper>            
        )
    }
}

export default SweepstakeAds