import styled from "@emotion/styled";
import mq from "@styles/mq";
import { Field } from "formik";
import React from "react";
import { Grid, GridColumn, GridRow, Segment } from "semantic-ui-react";

export const FormikFieldEmailValidationInput = styled(
    ({ inputValid, ...others }) => <Field {...others} />
  )`
    border-left: ${props =>
      props.inputValid === undefined
        ? ""
        : props.inputValid
        ? "4px solid green !important"
        : "4px solid red !important"};
    border-top: ${props =>
      props.inputValid === undefined
        ? ""
        : props.inputValid
        ? "1px solid green !important"
        : "1px solid red !important"};
    border-right: ${props =>
      props.inputValid === undefined
        ? ""
        : props.inputValid
        ? "1px solid green !important"
        : "1px solid red !important"};
    border-bottom: ${props =>
      props.inputValid === undefined
        ? ""
        : props.inputValid
        ? "1px solid green !important"
        : "1px solid red !important"};
`

export const HearderWrapper = styled.div`
  text-align: center;
  color: white;
  padding: 30px 0px 0px 0px;
  font-size: 18px;
  border-radius: 5px;
`

export const HearderNameWrapper = styled.div`
  text-align: center;
  color: white;
  padding: 8px 0px 10px 0px;
  font-size: 34px;
  font-weight: bold;
  border-radius: 5px;
`

export const PopupTitleWarpper = styled.span`  
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  margin: auto;
  color: white;
  font-size: 25px;
  font-weight: bold;
  `

export const FormTitleWrapper = styled.div`
  color: white;
  text-align: center;
  height: 25px;
  ${mq({
    fontSize: ["16px", "18px", "18px"]
  })}
`
export const ColumnWrapper = styled(GridColumn)`
  ${mq({
    paddingLeft: ["30px !important", "60px !important", "60px !important"],
    paddingRight: ["30px !important", "60px !important", "60px !important"]
  })}
`

export const RowWrapper = styled(GridRow)`
  padding-bottom: 0px !important;
`
export const RowWrapper2 = styled(GridRow)`
  padding-bottom: 0px !important;
  padding-top: 0px !important;
`
export const EmailOptInOuter = styled.div`
  margin-top: 20px;
`
export const SubTitleWrapper = styled.div`
  text-align: center;
  color: #ddd;
  ${mq({
    marginLeft: ["20px", "40px", "40px"],
    marginRight: ["20px", "40px", "40px"]
  })}
  font-weight: 100;
`
export const ImageWrapper = styled.img`
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width:100%;
`  
export const PopupTitleContainer = styled(GridRow)    
`
  padding-top:20px !important;
  margin-right: 20px;
  margin-left: 20px;
`

export const FormTitleRowContainer = styled(Grid.Row)
`  
  padding-top: 20px !important;
  padding-bottom: 20px !important;
`
export const ReCaptchaRowContainer = styled(Grid.Row)
`
  padding-bottom:0px !important;
  padding-top: 0px !important;
`
export const CampaignResponseWrapper = styled(GridRow)`    
  background-color: #3DAE2B;
  color: white;
  text-align: center;
  ${mq({
    padding: ["5px", "10px", "20px"],
    fontSize: ["14px", "14px", "20px"]
  })}
  div div h2 {
    margin-bottom:0px;
    font-size: 25px;
    font-weight: bold;
    margin-top:10px;
  }
  border-radius: 5px;
`
export const GenericSegmentWrapper = styled(({padding, ...rest})=><Segment {...rest} />)`
    &&&&{
        margin: 0;
        padding: 0;
        border: none;
        border-radius: none;
        padding-top: ${props=>props.paddingTop}px;
        padding-bottom: ${props=>props.paddingBottom}px;
        ${props => mq({
            paddingTop: [props.padding.mobile.top, props.padding.mobile.top, props.padding.desktop.top],
            paddingBottom: [props.padding.mobile.bottom, props.padding.mobile.bottom, props.padding.desktop.bottom]
        })}  
        border-radius: 10px;   
    }
`
export const SubTitleRowWrapper = styled(GridRow)`
    padding-top: 20px !important;
    padding-bottom: 0px !important;
    ${mq({
      height: ["35px", "35px", "35px"]     
  })}
`
export const FooterRowWrapper = styled(GridRow)`
    padding-top: 0px !important;
    padding-bottom: 30px !important;
`

export const Disclaimer = styled(({ color, textAlign, ...others }) => (
  <div {...others} />
))`
  ${mq({
    fontSize: ["13px", "13px", "13px"],
    lineHeight: ["18px", "18px", "18px"],
  })}
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  font-weight: 100;
  
  cursor: pointer;
  p{
    text-decoration: none;
  }
  p > a{
    color: ${props => props.color};
    text-decoration: underline;
  }
  span{
    color: ${props => props.color};
    text-decoration: none;
  }
`
export const EmailOptInWrapper = styled.span`
  margin-left:8px;
  margin-right: 0px;
  color: white;
  line-height: 20px;
  a{
    color: white;
    text-decoration: underline;
  }
`