import Axios from 'axios'
import {getplateSuggestions} from '../utils/ApiUrls';

const GetSuggestionsForCombination = async (apiUrl:string, startIndex: number, endIndex: number, leetSpeakAlt: boolean, popularNos: boolean, rangeOfNos: boolean, permutations: boolean, combination: string) =>{
    const url = getplateSuggestions(apiUrl,startIndex, endIndex, leetSpeakAlt, popularNos, rangeOfNos, permutations, combination);
    const result = await Axios.get(url);
    
    return result.data.Data;
}

export const PlateSuggestionService = {
    GetSuggestionsForCombination: GetSuggestionsForCombination
}