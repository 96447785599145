import React from 'react'
import { bool } from 'aws-sdk/clients/signer';

type AfterpayMessageProps = {
    amount: number,
    size?: string,
    isMobile: bool | undefined
}

export const AfterpayMessage = (props: AfterpayMessageProps) => {
    return (
        <>

            {/* @ts-ignore  */}
            <afterpay-placement
                data-locale="en_NZ"
                data-currency="NZD"
                data-amount={props.amount}
                data-size={props.size}
                style={props.isMobile ? {margin:"5px"} : {}}
            />
        </>
    );
}