import styled from "@emotion/styled";
import mq from '@styles/mq'
import theme from "@styles/theme";

export const PlateSuggestionsContainer = styled.div`
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    ${mq({
        padding: ['20px','30px','30px']
    })}
`


export const PlateSuggestionsHeadingContainer = styled.div`
    padding-bottom: 10px;
    margin-bottom: 25px;
    text-align: left;
    border-bottom: 2px solid ${theme.brand.colors.lightGrey};
`

export const PlateSuggestionContainer = styled.div`
    cursor: pointer;
    border-radius: 5px;
    border: solid 4px #000000;
    text-align: center;
    max-width: 175px;
    ${mq({
        maxWidth: ['150px','210px','175px']
    })}
    margin-bottom: 15px;

    > span {
        font-family: 'NZ Plate Webfont';
        ${mq({
            fontSize: ['30px','55px','48px'],
            lineHeight: ['30px','60px','53px']
        })}
    }
`