import React from 'react'

import {
    CombinationFinderSegment,
    CombinationFinderContainer,
    FinderTitle,
    InfoBar,
    VehicleTypeOptions,
    VehicleTypeListItem,
    VehicleTypeItem,
    CombinationLetterCounterContainer,
    CombinationLetterCounter,
    CombinationInput,
    CombinationActionContainer,
    PlateHole,
    CombinationInputRow,
    QuickCombinationInputContainer,
    QuickCombinationContentArea,
    CombinationInputContainerRowTop,
    CombinationInputContainerRowBottom,
    FinderSubTitle
} from './CombinationFinder.styles'
import { List,  Grid } from 'semantic-ui-react';
import { KpButton } from '@elements/index';
import theme from '@styles/theme';
import { VehicleType, CombinationSettings,VehicleTypesSetting } from '@models/ProductModels';
import QuickHelpInfoLink from './QuickHelpInfo';


interface FinderProps{
    title:string
    subtitle?: string
    hideVehicleOptions?: boolean
    vehicleType?: string,
    vehicleTheme?: string,
    apiUrl: string,
    reCaptchaSiteKey: string,
    reCaptchaSiteKeyV2: string,
    darkTheme: boolean
}
interface FinderStates{
    vehicleType: VehicleType
    combination:string
    oldCombination: string
    characterCombinationCount: number
    spacingCombinationCount: number
    vehicleTheme: any
    reCaptchaToken?: string
    reCaptchaError: string
}

class QuickCombinationFinder extends React.Component<FinderProps,FinderStates>{

    constructor(props:FinderProps){
        super(props);

        var vehicleType = VehicleTypesSetting.Car
        var vehicleTheme = theme.vehicleTheme.dark

        if(props.vehicleTheme === 'White'){
            vehicleTheme = theme.vehicleTheme.white
        }
        if(props.vehicleType === 'Motorbike'){
            vehicleType = VehicleTypesSetting.Motorbike
            vehicleTheme = theme.vehicleTheme.dark
        }
        if(props.vehicleType === 'Trailer'){
            vehicleType = VehicleTypesSetting.Trailer
            vehicleTheme = theme.vehicleTheme.white
        }

        this.state = {
            vehicleType: vehicleType,
            combination: '',
            oldCombination: '',
            characterCombinationCount: 0,
            spacingCombinationCount: 0,
            vehicleTheme: vehicleTheme,
            reCaptchaToken: undefined,
            reCaptchaError: ""
        }
    }


    handleInputChange = (event:any) =>{
        this.setState({combination: event.target.value});

        let combination = event.target.value;

        let largeCharLength = combination.replace(CombinationSettings.largeCharPattern, "").length;
        let smallCharLength = combination.replace(CombinationSettings.smallCharPattern, "").length;
        let spaceLength = combination.replace(CombinationSettings.spacePattern, "").length;
        let alphaNumLength  = largeCharLength + smallCharLength;
        let totalWidth = (largeCharLength * this.state.vehicleType.largeCharWidth) + (smallCharLength * this.state.vehicleType.smallCharWidth) + (spaceLength * this.state.vehicleType.spaceCharWidth);

        if(totalWidth <= this.state.vehicleType.maxWidth)
            this.setState({
                characterCombinationCount: alphaNumLength,
                spacingCombinationCount: spaceLength
        })

        if (alphaNumLength > this.state.vehicleType.maxChars || totalWidth > this.state.vehicleType.maxWidth) {
            this.setState({
                    combination: this.state.oldCombination
                });

        } else {
            var transformedInput = combination.replace(CombinationSettings.allowed, "");
            if (transformedInput !== combination) {
                this.setState({combination: transformedInput});
            }
            this.setState({
                    oldCombination: transformedInput
                });
        }
    }

    handleSubmit = (event:any) =>{       
        const combination = this.state.combination;            
    }


    changeVehicleType = (vehicleType: VehicleType) => {
        let vehicleTheme = null
        switch (vehicleType.name) {
            case VehicleTypesSetting.Motorbike.name:
            case VehicleTypesSetting.Car.name:
                vehicleTheme = vehicleTheme = theme.vehicleTheme.dark
                break;
            default:
                vehicleTheme = vehicleTheme = theme.vehicleTheme.white
                break;
        }

        this.setState({
            vehicleType: vehicleType,
            combination: '',
            characterCombinationCount: 0,
            vehicleTheme: vehicleTheme
        });
    }

    render(){
        const {title, subtitle, hideVehicleOptions, darkTheme} = this.props;
        const {vehicleType,combination,characterCombinationCount, vehicleTheme} = this.state;
        return (
        <CombinationFinderSegment basic>
            
            <QuickCombinationContentArea>
                <FinderTitle color={theme.brand.colors.white} margined={subtitle === undefined} textAlign='center'>
                    {title}
                </FinderTitle>
                {
                    subtitle &&
                    <FinderSubTitle color={theme.brand.colors.darkGrey} textAlign='center'>{subtitle}</FinderSubTitle>
                }

                <InfoBar>
                    <VehicleTypeOptions>
                        <List horizontal>
                            <VehicleTypeListItem>
                                <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Car.name} selected={vehicleType.name === VehicleTypesSetting.Car.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Car)}><span>{VehicleTypesSetting.Car.name}</span></VehicleTypeItem>
                            </VehicleTypeListItem>
                            {
                            !hideVehicleOptions &&
                                <VehicleTypeListItem>
                                    <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Trailer.name} selected={vehicleType.name === VehicleTypesSetting.Trailer.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Trailer)}><span>{VehicleTypesSetting.Trailer.name}</span></VehicleTypeItem>
                                </VehicleTypeListItem>
                            }
                            {
                            !hideVehicleOptions &&
                                <VehicleTypeListItem>
                                    <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Motorbike.name} selected={vehicleType.name === VehicleTypesSetting.Motorbike.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Motorbike)}><span>{VehicleTypesSetting.Motorbike.name}</span></VehicleTypeItem>
                                </VehicleTypeListItem>
                            }
                        </List>
                        {
                            !hideVehicleOptions &&
                            <QuickHelpInfoLink darkTheme={darkTheme}/>
                        }
                    </VehicleTypeOptions>
                    <CombinationLetterCounterContainer>
                        <CombinationLetterCounter color={theme.brand.colors.darkGrey}>
                            {characterCombinationCount}/{vehicleType.letters}
                        </CombinationLetterCounter>
                    </CombinationLetterCounterContainer>
                </InfoBar>
                <QuickCombinationInputContainer vehicleTheme={vehicleTheme} darkTheme={darkTheme}>
                    <Grid>
                        <CombinationInputContainerRowTop>
                            <Grid.Column width={4} floated='left'>
                                <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                            </Grid.Column>
                            <Grid.Column width={4} floated='right'>
                                <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                            </Grid.Column>
                        </CombinationInputContainerRowTop>
                        <CombinationInputRow centered>
                            <Grid.Column textAlign='center' width={16}>
                                <CombinationInput aria-label='Search Your Combination' spellCheck='false' autocomplete="off" autocorrect="off" placeholder={ this.state.vehicleType.id === 1 ? 'ABC123' : 'ABC12' } onChange={this.handleInputChange} value={combination} vehicleTheme={vehicleTheme}/>
                            </Grid.Column>
                        </CombinationInputRow>
                        <CombinationInputContainerRowBottom>
                            <Grid.Column width={4} floated='left'>
                                <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                            </Grid.Column>
                            <Grid.Column width={4} floated='right'>
                                <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                            </Grid.Column>
                        </CombinationInputContainerRowBottom>
                    </Grid>
                </QuickCombinationInputContainer>
                
                <CombinationActionContainer>
                    <KpButton fullWidth="mobile" id='qcfca' color={theme.brand.colors.green} disabled={characterCombinationCount <= 0}  buttonType='primary' link={`/create/?combination=${combination}&vt=${vehicleType.id}`}>
                        Check Availability
                    </KpButton>
                </CombinationActionContainer>
            </QuickCombinationContentArea>
        </CombinationFinderSegment>
        );
    }
}

export default QuickCombinationFinder;