import React from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import { PlateSuggestionService } from '@services/PlateSuggestionService';
import { PlateSuggestionContainer, PlateSuggestionsContainer, PlateSuggestionsHeadingContainer } from './PlateSuggestions.styles';
import { H5, H6 } from '@styles/Global.styles';
import { IVehicleType } from '@models/ProductModels/VehicleTypes';

interface PlateSuggestionsProps {
    apiUrl:string
    selectSuggestion: Function
    suggestionsToShow: number
    vehicleType: IVehicleType
}

interface PlateSuggestionsState {
    isLoading: boolean
    suggestions: string[],
    noSuggestions: boolean
}


class PlateSuggestions extends React.Component<PlateSuggestionsProps, PlateSuggestionsState>{
    constructor(props: PlateSuggestionsProps) {
        super(props)
        this.state = {
            isLoading: false,
            suggestions: [],
            noSuggestions: false
        }
    }

    mapSuggestions = (plateSuggestions:any) =>{
        var maxChars = this.props.vehicleType.maxChars;
        var suggestedCombinations:any[] = [];
        plateSuggestions.forEach((group: { suggestions: any[]; }) => {
            group.suggestions.forEach(item =>{
                if(suggestedCombinations.includes(item.Combination) == false && item.Combination.length <= maxChars){
                    suggestedCombinations.push(item.Combination);
                }
            })
        });
        return suggestedCombinations.slice(0,this.props.suggestionsToShow);
    }
    loadSuggestions = (combination: string) => {
        this.setState({
            isLoading: true
        });

        PlateSuggestionService.GetSuggestionsForCombination(this.props.apiUrl,1, 12, true, false, true, true, combination).then((plateSuggestions: any) => {
            if(plateSuggestions.length > 0){
                const suggestions = this.mapSuggestions(plateSuggestions);
                
                this.setState({
                    suggestions: suggestions,
                    isLoading: false,
                    noSuggestions: false,
                });
            }
            else {
                this.setState({
                    suggestions: [],
                    noSuggestions: true,
                    isLoading: false
                });
            }
        });
    }

    render(){
        const { isLoading, suggestions, noSuggestions } = this.state;
        return (
            <PlateSuggestionsContainer>
                <PlateSuggestionsHeadingContainer>
                    <H5>
                        Plate Suggestions
                    </H5>
                </PlateSuggestionsHeadingContainer>
                <Grid>
                    <Grid.Row>
                        {
                            !isLoading && suggestions.length > 0 &&
                            suggestions.map((suggestion) => {
                                return (
                                    <Grid.Column mobile={8} tablet={5} computer={8} key={suggestion}>
                                        <PlateSuggestionContainer onClick={() => this.props.selectSuggestion(suggestion)}>
                                            <span>
                                                {suggestion}
                                            </span>
                                        </PlateSuggestionContainer>
                                    </Grid.Column>
                                )
                            })
                        }
                        {
                            isLoading &&
                            <Grid.Column width={16}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16} textAlign='center'>
                                            <Icon name='circle notch' size='massive' loading />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        }
                        {
                            !isLoading && noSuggestions &&
                            <Grid.Column width={16}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16} textAlign='left'>
                                            <H6>OOPS! We couldn't find any available alternatives to that combination :(</H6>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        }
                    </Grid.Row>
                </Grid>
            </PlateSuggestionsContainer>
        )
    }
}

export default PlateSuggestions