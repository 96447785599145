import React, { Fragment } from "react"
import { Checkbox, Grid, Modal } from "semantic-ui-react";
import { UpperCaseArray, isMobile } from "@utils/Helpers";
import { ModalWrapper } from "./NotifyWhenAvailable.styles";
import { KpFormDropdown, KpFormField, KpFormFieldAsDT, KpFormInputErrorMessage } from "@styles/Global.forms";
import { KpButton } from "@elements/index";
import { ButtonTypes, LeadGenStatus, LeadGenType, LeadSource } from "@utils/Constant";
import theme from "@styles/theme";
import { EmailValidationService } from "@services/EmailValidationService";
import { LeadService } from "@services/LeadService";
import Cookies from "js-cookie";

interface ReservedThisPlateState {
  emailIsValid?: boolean
  currentEmail: string
  validatingEmail: boolean
  formData: any,
  isSubmitting: boolean,
  validated: boolean
}

interface ReservedThisPlateProps {
  apiUrl:string
  onClose: Function,
  modalOpen: boolean,
  combination: string,
  carMake: any,
  handleReservationNotification: any,
  emailValidationToken: string,
}
class NotifyWhenAvailable extends React.Component<ReservedThisPlateProps,ReservedThisPlateState>{

    constructor(props:ReservedThisPlateProps){
        super(props);
        this.state = {
          currentEmail: "",
          emailIsValid: false,
          validatingEmail: false,
          formData: {
            OptOut: false,
            AgreeTermAndCondition: false,
            carMake:''
          },
          isSubmitting: false,
          validated: false
        }
    }


    handleClose = () => {
        this.props.onClose();
    }

    handleValidation= () => {
      var message = '';
      var formData = this.state.formData

      let length = 12
      if(Object.keys(formData).find(e => e== 'carMake-error')){
        length = 13
      }

      if(Object.keys(formData).length === length-(this.props.carMake.length > 0 ? 0 : 1)){
        for (const key in formData) {
          if(key.includes('error')){

            if(formData[key] != ''){
              message = 'Error Value'
            }
            if(key == 'AgreeTermAndCondition'){
              if(formData[key] == false){
                message = 'Error Value'
              }
            }
          }
        }
      } else {
        message = 'Not All Filled up!'
      }
      this.setState({validated: message === ''})
    }

    handleFormData = (field: string, value: string) => {
      var formData = this.state.formData
      formData[field] = value

      if(field != 'carMake'){
        formData[`${field}-error`] = '';
        if(value.trim() === ""){
          formData[`${field}-error`] = `${UpperCaseArray(field)} is required!`;
        }
        this.setState({formData: formData}, () => {
          this.handleValidation()
        })
      } else {
        formData[`${field}-error`] = '';
        this.setState({formData: formData}, () => {
          this.handleValidation()
        })
      }     
    }

    handleCBFormDataValidation = (field: string, value: boolean) => {
      var formData = this.state.formData

      formData[field] = !value;

      formData[`${field}-error`] = '';
      if(!formData[field]){
        formData[`${field}-error`] = `Agree on term & condition is required!`;
      }
      this.setState({formData: formData}, () => {
        this.handleValidation()
      })
    }

    handleCBFormData = (field: string, value: boolean) => {
      var formData = this.state.formData
      formData[field] = !value;
      this.setState({formData: formData})
    }

    handleSubmit = () => {
      this.setState({isSubmitting: true})
      var formData = this.state.formData
      const postData = {
            "FirstName": formData.firstName,
            "LastName": formData.lastName,
            "Email": formData.email,
            "Phone": formData.mobile,
            "Subject": "",
            "Message": "",
            "VehicleMake": formData.carMake,
            "VehicleType": "Car",
            "Combination": this.props.combination,
            "ReservationType": 8,
            "ToBeNotified": true,
            "OptOut": formData.OptOut,
            "Campaign": "",
            "LeadSource": LeadSource.Notification,
            "Type": LeadGenType.Notification,
            "Status": LeadGenStatus.ToBeNotify
        };


      this.setState({
        isSubmitting: true
      })
      Cookies.set('click-notification', "true");

      LeadService.reserveNotifyCombination(
          this.props.apiUrl,
          postData
      ).then((result) => {
          this.setState({
            isSubmitting: false,
            formData: {}
          }, () => {
            this.props.handleReservationNotification(LeadGenStatus.ToBeNotify);
          })
      });
    }

    validateEmailAddress = (field: string, value: any) => {
      var formData = this.state.formData
      formData[field] = value
      if (this.state.currentEmail !== value) {
        this.setState({
          currentEmail: value,
          validatingEmail: true,
          emailIsValid: undefined,
        })
        let error
        return EmailValidationService.validate(
          this.props.emailValidationToken,
          value,
          true
        ).then((result: any) => {
          formData[`${field}-error`] = '';
          if(value.trim() === ""){
            formData[`${field}-error`] = `${UpperCaseArray(field)} is required!`;
          }
          if (result.success == true) {
            formData[`${field}-error`] = ``;
            this.setState({
              validatingEmail: false,
              emailIsValid: true,
              formData: formData
            }, () => {this.handleValidation()})

          } else {
            formData[`${field}-error`] = `Invalid Email`;
            this.setState({
              validatingEmail: false,
              emailIsValid: false,
              formData: formData
            }, () => {this.handleValidation()})
          }
        })
      } else if (this.state.emailIsValid == false) {
        return "Invalid Email"
      }
    }

    render() {

        return (
            <Modal className="lead-gen-modal" open={this.props.modalOpen} onClose={this.handleClose} centered={true} size={isMobile() ? "fullscreen" : "tiny"}>

                <ModalWrapper>
                <div className="close" onClick={this.handleClose}>
                  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.3828 2.88281C12.8711 2.39453 12.8711 1.60156 12.3828 1.11328C11.8945 0.625 11.1016 0.625 10.6133 1.11328L6.5 5.23047L2.38281 1.11719C1.89453 0.628906 1.10156 0.628906 0.613281 1.11719C0.125 1.60547 0.125 2.39844 0.613281 2.88672L4.73047 7L0.617188 11.1172C0.128906 11.6055 0.128906 12.3984 0.617188 12.8867C1.10547 13.375 1.89844 13.375 2.38672 12.8867L6.5 8.76953L10.6172 12.8828C11.1055 13.3711 11.8984 13.3711 12.3867 12.8828C12.875 12.3945 12.875 11.6016 12.3867 11.1133L8.26953 7L12.3828 2.88281Z" fill="#707070"/>
                  </svg>
                </div>
                    <img style={{width:"53.33px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGsAAABQCAYAAAAeAotiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAATrSURBVHhe7Z2/UxNBFMfz//gPaGGJM7Qw2CL0oTfWBNs4asmMdlioM4xIYQpjIWpkoBAcaHSGHzNagCAFNme+l1tILu+S3Xt7d7uX95n5Nrnkctkvuffe7ltSCTTY/PUpaGzVg2rrXjCzfie4/fKGyIImV2+GY4qxxRiPYqhZOAFORr2RyL5m1ieCNz9eRaM/CGnW+eWfoPaxSp5QlL3q7Vpw9PcgcuOaAbOOO0+623GYOokoP+FbFjeszywxyi3FDesza745Rb5IVJwWOjmD4sqslf3n5JNFxWtl/1noUWiW3P7cFlL888uzrllrnXSRepLIHeHbFZpV/SC1lOtC7Kqc/zsjD1KaXL0VFm14jcADY4g7Gm5x1FhTqmz+/kweoLR3+j16K8EWJuOvbVb1/Wx0esE2c81pcszjqugmF4tfa9GpBdsstmvkmMclZjmAmOURYpZHiFkeYd0sSM1RCfYwmZM1Mgta3nkSvY3A5dH2Q3KMk2RsFoRVZGolU9ADK/Fp2iVSmQVRK5nCaDgrHKnNgjCv1Tp8F12GMAo0IJnMBcbFMktJ4thobCzuWjELQrAUBkF8Mk0kkmTNLGi+OS1xrAfEJ5t9LVbNgiTx6LJ3smu9VcK6WRCC6DgX0Gs/X7MSiSRlYpbSOCYey7tPybGwoUzNglBAozOn7OTRcp65WVDZ4xi3lQ+9LdTjcWmbhVl33NaoYzqCYTrbWnyDW+g2tpayWyJ5wSzuyhTHuIUuxhJkup61d4q0NP2mujIU0JxCFxsSezvFMl98PL44ZBnmawGddsZcKfzcnbHrJZeVYjQqNlh/YX4lHtxEov6lRjbI5mKWgpN4IDgP25rpCtxCd/nb4+hMg+RqFmgdNnkfxuHEg1PoIi3HGA8jd7MAN45hL61LBTTiU739gLxWHSGRiMcnikLMAjAMQZQ6h45ciWPcGfNqa1Z7A0dhZim4BTRmrYuCO2OOQteEws0CHMOgImbubRW6JjhhFuAW0HkmHpxCF4kEduSkwRmzADfxwK6/LOMYt9DVTSSScMosgGBb20i/hJBV4sEtdGsbC9qJRBLOmaXgFtA2W9+4M+bDCl0TnDULIAgXXUBzEgmdQtcEp80C3DiGZCBNAc1tDYvPmNvAebOAjQLaZF4Rtz1OfKJmzG3ghVkKzsw9BNNwW9s72YnOeA0ewzHu/000LXRN8MoswC2ge4VbFUQdS6M0ha4J3pkFMHPPiWO2xSl0TfDSLMBNPGyJW+ia4K1ZgFtAc2UyY24Dr81S2IxjusoykUiiFGYBbgGtK8SnrBOJJEpjFsg6juG2l1d8oiiVWQpcq03TkES40KxTSrMUuGZ8G6jr1BFe69Je6FKbpcDtEdePDzvXnArjTvy68RiOLbbvh8/NM8vTZSzMSuLo4iCUL4y1Wb4hZnmEmOURYpZHiFkeIWZ5hLZZWEOiDsSFf2ctZIPuL1ZU0NdNHaBUxg3cRWMy/oY/b9Hd+IYOIYEHxhAhyGRFIfzhmAX54RjnhV+sCM3i7pwQZS/c0UKzcCvMY4FPlE6qHyQ0C2CVlHqiqHipFewrs4DELveELmBFn1mutIGJuoq3w/WZBcQwN0T1LQ6YBWCY7hSIyL6SNuiRZilQtMm3LD+hN2RzSLv2ULMUOEFjeyk8GdXnIEqnmbcT4ZhibIeZ1CUI/gM9gK+M9XDsUAAAAABJRU5ErkJggg==" width="100%" alt="rwetrt plate image" className="ui centered image css-4vx0xo e99udpt9"/>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                    <div className="title">Notify Me When Available<span className='subtitle'>BETA</span></div>
                    <div className="summary">Plates are reserved for up to 3 days. We'll notify you when this plate is no longer reserved.</div>
                    <div className="plate">Plate combination: <span>{this.props.combination.toUpperCase()}</span></div>
                    <div style={{padding: "20px"}}>
                    <Grid width={16}>
                        <Grid.Row>
                          <Grid.Column
                            mobile={8}
                            tablet={8}
                            computer={8}
                            textAlign="center"
                            className="coll"
                          >
                            <KpFormFieldAsDT>
                              <KpFormInputErrorMessage>
                                  {this.state.formData['firstName-error']}
                              </KpFormInputErrorMessage>
                              <input
                                id="firstName"
                                name="firstName"
                                type="text"
                                data-private="lipsum"
                                aria-label="First Name"
                                placeholder="First Name"
                                value={this.state.formData['firstName']}
                                onChange={e => this.handleFormData('firstName', e.target.value)}
                              ></input>
                            </KpFormFieldAsDT>
                          </Grid.Column>
                          <Grid.Column
                            mobile={8}
                            tablet={8}
                            computer={8}
                            textAlign="center"
                            className="colr"
                          >
                            <KpFormFieldAsDT>
                              <KpFormInputErrorMessage>
                                  {this.state.formData['lastName-error']}
                              </KpFormInputErrorMessage>
                              <input
                                id="lastName"
                                name="lastName"
                                aria-label="Last Name"
                                data-private="lipsum"
                                type="text"
                                placeholder="Last Name"
                                value={this.state.formData['lastName']}
                                onChange={e => this.handleFormData('lastName', e.target.value)}
                              ></input>
                            </KpFormFieldAsDT>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="center"
                          >

                            <KpFormFieldAsDT>
                              <KpFormInputErrorMessage>
                                  {this.state.formData['email-error']}
                              </KpFormInputErrorMessage>
                              <input
                                id="email"
                                name="email"
                                type="text"
                                data-private="lipsum"
                                aria-label="Email"
                                placeholder="Email"
                                value={this.state.formData['email']}
                                onChange={e => this.validateEmailAddress('email', e.target.value)}
                              ></input>
                            </KpFormFieldAsDT>
                          </Grid.Column>

                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="center"
                          >
                            <KpFormFieldAsDT>
                              <KpFormInputErrorMessage>
                                  {this.state.formData['mobile-error']}
                              </KpFormInputErrorMessage>
                              <input
                                id="mobile"
                                name="mobile"
                                type="number"
                                data-private="lipsum"
                                aria-label="Mobile"
                                placeholder="Mobile"
                                value={this.state.formData['mobile']}
                                onChange={e => this.handleFormData('mobile', e.target.value)}
                                min="0"
                              ></input>
                            </KpFormFieldAsDT>
                          </Grid.Column>

                        </Grid.Row>
                        {this.props.carMake.length > 0 && <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="center"
                          >
                            <KpFormField>
                              <KpFormDropdown
                                id="carMake"
                                fluid
                                selection
                                placeholder={(<span style={{color: "#707070"}}>Car Make</span>)}
                                backgroundColor={theme.brand.colors.white}
                                icon="chevron down"
                                options={this.props.carMake}
                                value={this.state.formData['carMake']}
                                onChange={(e:any) => this.handleFormData('carMake', e.currentTarget.innerText.includes("\n") ? '' : e.currentTarget.innerText)}
                              />
                            </KpFormField>
                          </Grid.Column>

                        </Grid.Row>}
                        <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="left"
                            style={{marginBottom:"12px"}}
                          >
                            <Checkbox
                                style={{display:"inline"}}
                                id="optIn"
                                className="bcopt"
                                onClick={(e:any) => this.handleCBFormData('OptOut', this.state.formData['OptOut'])}
                                checked={!this.state.formData['OptOut']}/> <div className="span">Keep me updated on this plate combination, new plate designs and promotions</div>
                          </Grid.Column>
                          {this.state.formData['AgreeTermAndCondition-error'] && <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="left"
                            style={{marginBottom:"12px"}}
                          >
                              <KpFormInputErrorMessage>
                                  {this.state.formData['AgreeTermAndCondition-error']}
                              </KpFormInputErrorMessage>                            
                          </Grid.Column>}
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="left"
                            style={{marginBottom:"12px"}}
                          >
                            <Checkbox
                                style={{display:"inline"}}
                                id="agreetc"
                                className="bcopt"
                                onClick={(e:any) => this.handleCBFormDataValidation('AgreeTermAndCondition', this.state.formData['AgreeTermAndCondition'])}
                                checked={this.state.formData['AgreeTermAndCondition']}/> <div className="span">I agree to the </div><a href="/terms-and-conditions/?reserved=1" className="tc" target="_blank">Terms & Conditions</a><div  className="span-right"></div>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="center"
                            style={{marginBottom:"12px"}}
                          >
                            <KpButton
                                id="notifyWhenAvailableButton"
                                color={theme.brand.colors.green}
                                fullWidth="mobile tablet computer"
                                buttonType={ButtonTypes.Primary}
                                loading={this.state.isSubmitting}
                                type="submit"
                                disabled={!this.state.validated}
                                onClick={this.handleSubmit}
                            >
                                NOTIFY ME
                            </KpButton>
                          </Grid.Column>

                        </Grid.Row>
                      </Grid>
                    </div>
                    </Grid.Column>
                </ModalWrapper>
            </Modal>)

    }
}

export default NotifyWhenAvailable