import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { Popup, Icon } from "semantic-ui-react";
import { VehiclyTypePopupContainer, QuickHelpInfo, QuickHelpInfoDarkTheme } from "./CombinationFinder.styles";
import { H5, PopupContentText } from "@styles/Global.styles";

const QuickHelpInfoLink = ({darkTheme}) =>{
    const data = useStaticQuery(
        graphql`{
            kontentItemVehicleTypeTooltip {
                elements {
                    body {
                        value
                    }
                    heading {
                        value
                    }
                }
            }
        }
        `,
      );
    return (
        darkTheme ? 
            <QuickHelpInfoDarkTheme>
                <Popup basic position='right center' trigger={<Icon name='question circle outline' color='orange' size='small' />}>
                    <VehiclyTypePopupContainer>
                        <H5>{data.kontentItemVehicleTypeTooltip.elements.heading.value}</H5>
                        <PopupContentText dangerouslySetInnerHTML={{ __html: data.kontentItemVehicleTypeTooltip.elements.body.value }}></PopupContentText>
                    </VehiclyTypePopupContainer>
                </Popup>
            </QuickHelpInfoDarkTheme> : 
            <QuickHelpInfo>
                <Popup basic position='right center' trigger={<Icon name='question circle outline' color='orange' size='small' />}>
                    <VehiclyTypePopupContainer>
                        <H5>{data.kontentItemVehicleTypeTooltip.elements.heading.value}</H5>
                        <PopupContentText dangerouslySetInnerHTML={{ __html: data.kontentItemVehicleTypeTooltip.elements.body.value }}></PopupContentText>
                    </VehiclyTypePopupContainer>
                </Popup>
            </QuickHelpInfo>
        
        
    )
}
export default QuickHelpInfoLink