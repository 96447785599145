import { KpButton } from "@elements/index";
import { Campaign } from "@models/Campaign/Campaign"
import { EmailValidationService } from "@services/EmailValidationService";
import { ReCaptchaService } from "@services/ReCaptchaService";
import { SubscriptionService } from "@services/SubscriptionService";
import { KpFormErrorMessageLeft, KpFormField, KpFormInputErrorMessage } from "@styles/Global.forms";
import theme from "@styles/theme";
import { EmailRegex, FullNameRegex, PhoneRegex, ReCaptchaThreshold } from "@utils/Constant";
import ReCaptcha from "@utils/reCaptcha";
import { Formik } from "formik";
import moment from "moment";
import React from "react"
import { Checkbox, Form, FormGroup, Grid, Input, Modal } from "semantic-ui-react";
import * as Yup from 'yup';
import { CampaignResponseWrapper, ColumnWrapper, Disclaimer, EmailOptInOuter, EmailOptInWrapper, FooterRowWrapper, FormTitleRowContainer, FormTitleWrapper, FormikFieldEmailValidationInput, GenericSegmentWrapper, HearderNameWrapper, HearderWrapper, ImageWrapper, PopupTitleContainer, ReCaptchaRowContainer, RowWrapper, RowWrapper2, SubTitleRowWrapper, SubTitleWrapper } from "./SweepstakeCampaign.styles";
import { convertKontentColorToHex, isMobile } from "@utils/Helpers";
import { LOCALSTORAGE_CAMPAIGN_CURRENT } from "@redux/localStorage";
import Helmet from "react-helmet";

interface SweepstakeCampaignState {
    reCaptchaToken?: string
    reCaptchaError: string
    emailIsValid?: boolean
    currentEmail: string
    validatingEmail: boolean
    widget_v2: any
}

interface SweepstakeCampaignProps {
    apiUrl:string
    reCaptchaSiteKeyV2: string,
    reCaptchaSiteKey: string,
    campaign: Campaign,
    emailValidationToken: string,
    onClose: Function,
    modalOpen: boolean,
}
class SweepstakeCampaign extends React.Component<SweepstakeCampaignProps,SweepstakeCampaignState>{

    constructor(props:SweepstakeCampaignProps){
        super(props);
        this.state = {
            reCaptchaError: "",
            reCaptchaToken: undefined,
            currentEmail: "",
            emailIsValid: false,
            validatingEmail: false,
            widget_v2: undefined
        }
    }

    intialStatus = {
        isSubmitting: false,
        isSubmitted: false
    }

    initialValues = {
        fullName: '',
        phone: '',
        email: '',
        promoSubscribed: true
    }

    validateEmailAddress = (value: any) => {
        if (this.state.currentEmail !== value) {
          this.setState({
            currentEmail: value,
            validatingEmail: true,
            emailIsValid: undefined,
          })
          let error
          return EmailValidationService.validate(
            this.props.emailValidationToken,
            value,
            true
          ).then((result: any) => {
            if (result.success == true) {
              this.setState({
                validatingEmail: false,
                emailIsValid: true,
              })
            } else {
              error = "Invalid Email"
              this.setState({
                validatingEmail: false,
                emailIsValid: false,
              })
              return error
            }
          })
        } else if (this.state.emailIsValid == false) {
          return "Invalid Email"
        }
      }

    submitForm = async (values: any, actions: any, execute: () => Promise<string>, widgetV2: number | undefined) => {
        actions.setStatus({
            isSubmitting: true,
            isSubmitted: false,
        })

        let recaptcha_v2: HTMLElement | null
        if(document){
            recaptcha_v2 = document.getElementById("g-recaptcha_campaign-v2")
        } else {
            return
        }

        if(recaptcha_v2 && recaptcha_v2.style.display == "block"){
            const token = window.grecaptcha.getResponse(widgetV2)
            ReCaptchaService.validateCaptcha(this.props.apiUrl, token, "v2").then(async (response: any) => {
                if (response && response.data.Success)
                {
                this.setState({reCaptchaError: ""})
                this.processSubmit(values, actions)
                } else {
                actions.setStatus({
                    isSubmitting: false,
                    isSubmitted: false
                })
                this.setState({reCaptchaError: "Please tick checkbox correctly"})
                return false
                }
            })

        } else {
            const token = await execute()
            this.setState({ reCaptchaToken: token })

            ReCaptchaService.validateCaptcha(this.props.apiUrl, token, "v3").then(async (response: any) => {
                if (response.status != 200) return 'recaptcha validate error'

                if (response.data.Data && (!response.data.Data.success || response.data.Data.score < ReCaptchaThreshold)) {
                actions.setStatus({
                    isSubmitting: false,
                    isSubmitted: false
                })
                if (document && recaptcha_v2){
                    recaptcha_v2!.style.display = "block"
                }
                return false
                } else {
                this.processSubmit(values, actions)
                }
            })
        }
    }

    processSubmit = (values: any, actions: any) =>{
        var phoneFomatted = this.updatePhoneNumber(values.phone)

        var campaignMember = {
            firstname: values.fullName.trim().split(' ')[0],
            lastname: values.fullName.trim().split(' ')[1],
            email: values.email,
            phone: phoneFomatted,
            address: "",
            referral_email: "",
            referral_name: "",
            own_a_plate: "",
            likely_to_buy: "",
            gender: "",
            current_plate_combination: "",
            job_title: "",
            hasOptedOutOfEmail: !values.promoSubscribed
        }
        var campaignValues ={
            campaignName: this.props.campaign.name,
            validFrom: new Date(this.props.campaign.validFrom),
            expiredOn: new Date(this.props.campaign.expiresOn),
            transactionTypes: this.props.campaign.transactionTypes.map(x=>x.name).join(';')
        }
        SubscriptionService.subscribeUserRequest(
            this.props.apiUrl,
            campaignMember,
            this.props.campaign.name,
            campaignValues,
            true
          ).then(data => {
            actions.setStatus({
                isSubmitting: false,
                isSubmitted: true
            })

            localStorage.setItem(LOCALSTORAGE_CAMPAIGN_CURRENT, JSON.stringify({campaignName: campaignValues.campaignName, email: campaignMember.email, time:Date.now()}));
          })
    }

    handleClose = () => {
        this.props.onClose();
    }

    renderCampaignResponse = () =>{
        return(
            <Grid.Column mobile={16} tablet={16} computer={16}>
                <CampaignResponseWrapper>
                    <Grid.Column width={16} textAlign='center'>
                        <div dangerouslySetInnerHTML={{ __html: this.props.campaign.campaignPopup.response }}></div>
                    </Grid.Column>
                </CampaignResponseWrapper>
            </Grid.Column>
         )
    }

    updatePhoneNumber = (phone: string) => {
        if (!phone.startsWith('+64')){
          if (phone.startsWith('0064')){
            phone = phone.replace('0064', '+64')
          }
          if(phone.startsWith('0')){
            phone = phone.replace('0', '+64 ')
          }
          if(!phone.startsWith('+64')) {
            phone = "+64 ".concat(phone);
          }
        }
        if (!phone.startsWith('+64 ')){
            phone = phone.replace('+64', '+64 ')
        }

        return phone
    }

    render() {
        const validationSchema = Yup.object({
            fullName: Yup.string()
                .matches(FullNameRegex, "Enter valid full name")
                .required('Full Name is required'),
            phone: Yup.string()
                .matches(PhoneRegex, "Invalid phone number")
                .min(9, 'Must be at least 9 digits')
                .required('Phone is required'),
            email: Yup.string()
                .required('Email is required')
                .matches(EmailRegex ,'Must enter a valid email address'),
            promoSubscribed: Yup.bool()
                .oneOf([true], "* required")
                .required("* required")
        })
        const bgColor = convertKontentColorToHex(this.props.campaign.campaignPopup.background_color.value[0].codename);
        const inputBackgroundColor = convertKontentColorToHex(this.props.campaign.campaignPopup.input_field_background_color.value[0].codename);
        const expiredOn = moment(new Date(this.props.campaign.expiresOn.toString())).format("DD/MM/YYYY")
        const titlePadding = {
            mobile: {
                top: 0,
                bottom: 10
            },
            desktop: {
                top: 0,
                bottom: 10
            }
        }

        return (
            <Modal open={this.props.modalOpen} onClose={this.handleClose} closeIcon centered={true} size={isMobile() ? "fullscreen" : "tiny"}>
                <ReCaptcha action="campaign" reCaptchaSiteKey={this.props.reCaptchaSiteKey} reCaptchaSiteKeyV2={this.props.reCaptchaSiteKeyV2}>{captcha => (
                <Grid.Column mobile={16} tablet={16} computer={16} style={{ backgroundColor: bgColor}}>
                        <Formik initialValues={this.initialValues} validationSchema={validationSchema} initialStatus={this.intialStatus}
                        onSubmit={(values: any, actions: any) => this.submitForm(values, actions, captcha.execute, captcha.widgetV2)}>
                            { props => (
                                <div>
                                {!props.status.isSubmitted &&
                                <Form onSubmit={props.handleSubmit}>
                                    <HearderWrapper>{this.props.campaign.campaignPopup.header}</HearderWrapper>
                                    <HearderNameWrapper>{this.props.campaign.campaignPopup.header_campaign_name}</HearderNameWrapper>
                                    <GenericSegmentWrapper basic padding={titlePadding}>
                                        <Grid >
                                            <PopupTitleContainer>
                                                {this.props.campaign.campaignPopup.picture.length > 0 &&
                                                    <Grid.Column mobile={16} tablet={16} computer={16}>
                                                        <ImageWrapper src={this.props.campaign.campaignPopup.picture[0].url} alt={this.props.campaign.campaignPopup.picture.name}/>
                                                    </Grid.Column>
                                                }
                                            </PopupTitleContainer>
                                        </Grid>
                                    </GenericSegmentWrapper>
                                    <GenericSegmentWrapper basic padding={titlePadding} center>
                                    {
                                        <Grid>
                                            <FormTitleRowContainer centered columns={3}>

                                                <Grid.Column textAlign="center" mobile={16} tablet={12} computer={12}>
                                                    <FormTitleWrapper dangerouslySetInnerHTML={{ __html: this.props.campaign.campaignPopup.form_title}} >
                                                    </FormTitleWrapper>
                                                </Grid.Column>

                                            </FormTitleRowContainer>
                                            <RowWrapper>
                                                <ColumnWrapper>
                                                    <Form.Group>
                                                        <KpFormField required error={props.errors.fullName && props.touched.fullName} backgroundColor={inputBackgroundColor} placeholderColor="#ddd">
                                                            {
                                                                props.errors.fullName && props.touched.fullName && (
                                                                    <KpFormInputErrorMessage>
                                                                        {props.errors.fullName}
                                                                    </KpFormInputErrorMessage>
                                                                )
                                                            }
                                                            <input
                                                                id='fullName'
                                                                name='fullName'
                                                                data-private="lipsum"
                                                                type='text'
                                                                aria-label='fullName'
                                                                disabled={props.status.isSubmitting}
                                                                placeholder='Full Name'
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                autoComplete="off"
                                                                value={props.values.fullName}>
                                                            </input>
                                                        </KpFormField>
                                                    </Form.Group>
                                                </ColumnWrapper>
                                            </RowWrapper>
                                            <RowWrapper>
                                                    <ColumnWrapper>
                                                    <Form.Group>
                                                        <KpFormField required error={props.errors.email && props.touched.email} backgroundColor={inputBackgroundColor} placeholderColor="#ddd">
                                                            {
                                                                props.errors.email && props.touched.email && (
                                                                    <KpFormInputErrorMessage>
                                                                        {props.errors.email}
                                                                    </KpFormInputErrorMessage>
                                                                )
                                                            }
                                                            <FormikFieldEmailValidationInput
                                                                as={Input}
                                                                id="email"
                                                                name="email"
                                                                data-private="lipsum"
                                                                type="text"
                                                                aria-label="Email"
                                                                placeholder='Email'
                                                                disabled={props.status.isSubmitting}
                                                                onChange={props.handleChange}
                                                                validate={this.validateEmailAddress}
                                                                autoComplete="off"
                                                                value={props.values.email}
                                                                />
                                                        </KpFormField>
                                                    </Form.Group>
                                                </ColumnWrapper>
                                            </RowWrapper>
                                            <RowWrapper>
                                                <ColumnWrapper>
                                                    <Form.Group>
                                                        <KpFormField required error={props.errors.phone && props.touched.phone} backgroundColor={inputBackgroundColor} placeholderColor="#ddd">
                                                            {
                                                                props.errors.phone && props.touched.phone && (
                                                                    <KpFormInputErrorMessage>
                                                                        {props.errors.phone}
                                                                    </KpFormInputErrorMessage>
                                                                )
                                                            }
                                                            <input
                                                                id='phone'
                                                                name='phone'
                                                                data-private="lipsum"
                                                                aria-label='Phone Number'
                                                                type='text'
                                                                disabled={props.status.isSubmitting}
                                                                placeholder='Mobile (+64 21 1234567)'
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                autoComplete="off"
                                                                value={props.values.phone}>
                                                            </input>
                                                        </KpFormField>
                                                    </Form.Group>
                                                </ColumnWrapper>
                                            </RowWrapper>
                                            <RowWrapper2>
                                                <ColumnWrapper>
                                                    <FormGroup>
                                                        <KpFormField labelColor="white" backgroundColor={inputBackgroundColor}>
                                                        {
                                                                props.errors.promoSubscribed && props.touched.promoSubscribed && (
                                                                    <KpFormInputErrorMessage>
                                                                        {props.errors.promoSubscribed}
                                                                    </KpFormInputErrorMessage>
                                                                )
                                                            }
                                                            <EmailOptInOuter>
                                                                <Checkbox
                                                                id="promoSubscribed"
                                                                onClick={() => {
                                                                    props.setFieldValue(
                                                                    "promoSubscribed",
                                                                    !props.values.promoSubscribed
                                                                    )
                                                                }}
                                                                checked={props.values.promoSubscribed}
                                                                ></Checkbox>
                                                                <EmailOptInWrapper dangerouslySetInnerHTML={{ __html: this.props.campaign.campaignPopup.email_opt_in_text.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '')}}></EmailOptInWrapper>
                                                            </EmailOptInOuter>
                                                        </KpFormField>
                                                    </FormGroup>
                                                </ColumnWrapper>
                                            </RowWrapper2>
                                            <RowWrapper>
                                                <ColumnWrapper>
                                                    <ReCaptchaRowContainer>
                                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                                            {this.state.reCaptchaError && <KpFormErrorMessageLeft>{this.state.reCaptchaError}</KpFormErrorMessageLeft>}
                                                            <div className="g-recaptcha-v2" data-sitekey={this.props.reCaptchaSiteKeyV2} id="g-recaptcha_campaign-v2" style={{display:"none"}}></div>
                                                        </Grid.Column>
                                                    </ReCaptchaRowContainer>
                                                </ColumnWrapper>
                                            </RowWrapper>
                                            <RowWrapper>
                                                <ColumnWrapper>
                                                    <KpButton id="cub-send" type='submit' fullWidth="mobile tablet computer" loading={props.status.isSubmitting} buttonType='primary' color={theme.brand.colors.black} textColor={theme.brand.colors.white}>SIGN UP</KpButton>
                                                </ColumnWrapper>
                                            </RowWrapper>
                                            <SubTitleRowWrapper>
                                                <Grid.Column>
                                                    <SubTitleWrapper>
                                                        {this.props.campaign.campaignPopup.footer} <span>{expiredOn}</span>
                                                    </SubTitleWrapper>
                                                </Grid.Column>
                                            </SubTitleRowWrapper>
                                            <FooterRowWrapper>
                                                <Grid.Column>
                                                    <Disclaimer textAlign="center" color="#ddd" dangerouslySetInnerHTML={{ __html: this.props.campaign.campaignPopup.terms___conditions}} ></Disclaimer>
                                                </Grid.Column>
                                            </FooterRowWrapper>
                                        </Grid>
                                    }
                                    </GenericSegmentWrapper>
                                </Form>}
                                {props.status.isSubmitted && (
                                    this.renderCampaignResponse()
                                )}
                                </div>
                            )}
                        </Formik>
                </Grid.Column>
                )}</ReCaptcha>
            </Modal>)

    }
}

export default SweepstakeCampaign