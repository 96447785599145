import { getValidateCaptchaUrl } from "@utils/ApiUrls";
import Axios from "axios";

const validateCaptcha = async (apiUrl: string, token: string, version: string) => {   
    const url = getValidateCaptchaUrl(apiUrl, token, version);
    return Axios.post(url);
}

export const ReCaptchaService = {
    validateCaptcha: validateCaptcha
}