import styled from "@emotion/styled";
import mq from "@styles/mq";
import { Field } from "formik";
import React from "react";
import { Grid, GridColumn, GridRow, Segment } from "semantic-ui-react";

export const ModalWrapper = styled.div`
  ${mq({
    paddingLeft: ['0px','0px','15px'],
    paddingRight: ['0px','0px','15px']
  })}

  .row{
    padding: 8px 0px 8px 0px !important;
  }

  .bcopt{
    label:after {
      background: #3DAE2B !important;
      color: #fff !important;
      font-size: 11px;
      font-weight: 100;
      border-radius: 4px;
    }
  }

  .text{
    font-weight: 100 !important;
    font-family: sans-serif;
  }

  padding-top: 32px;
  padding-bottom: 12px;
  img {
    width: 204px;
  }

  .span{
    margin-left: 25px;
    display: inline-block;
    padding-top: 2px;
    color: #707070;
    font-size: 12px;
    font-weight: 100;
  }

  .close{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor:pointer;
  }

  .title {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
    padding: 8px 30px 8px 30px;
  }

  .summary {
    font-size: 14px;
    color: #707070;
    text-align: center;
    padding: 8px 8px 8px 8px;
    font-weight: 100;
  }

  .plate {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;

    span {
      font-size: 16px;
      color: #3DAE2B;
    }
  }

  .tc{
        color: #3DAE2B !important;
        font-size: 12px !important;
        font-weight: 500;
        margin-left: 5px;
      }
  .tnc {
    font-size: 12px;
    color: #707070;
    text-align: center;
    padding: 10px 40px 20px 40px;
    font-weight: 100;

    a:link {
      color: #707070;
    }
    a:visited {
      color: #707070;
    }

    a:hover {
      color: #707070;
    }
  }

  .coll {
    padding-right: 6px !important;
  }

  .colr {
    padding-left: 6px !important;
  }

  input {
    border: 1px solid #00000021 !important;
    border-radius: 3px !important;
  }
    .subtitle{
    color: #707070;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    vertical-align: text-top;
    margin-left:4px;
  }
    .span-right{
    margin-left: 5px;
    display: inline-block;
    padding-top: 2px;
    color: #707070;
    font-size: 12px;
    font-weight: 100;
  }
`