import Axios from 'axios'
import {checkCombinationUrl} from '../utils/ApiUrls';

const checkCombination = async (apiUrl: string, combination:string, vehicleType:number) =>{
    const url = checkCombinationUrl(apiUrl,combination, vehicleType);
    const result = await Axios.get(url);
    
    return result.data.Data;
}

export const CheckCombinationService = {
    checkCombination: checkCombination
}