import styled from "@emotion/styled";
import mq from "@styles/mq";
import { Field } from "formik";
import React from "react";
import { Grid, GridColumn, GridRow, Segment } from "semantic-ui-react";

export const ModalWrapper = styled.div`
  ${mq({
    paddingLeft: ['0px','0px','20px'],
    paddingRight: ['0px','0px','20px']
  })}

  padding-top:32px;
  padding-bottom: 15px;
  img {
    width: 204px;
  }

  .title {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
  }

  .summary {
    font-size: 16px;
    color: #707070;
    text-align: center;
    padding: 15px 8px 8px 8px;
    font-weight: 100;
  }
  .close{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor:pointer;
  }

  .plate {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin-top: 12px;

    span {
      font-size: 16px;
      color: #3DAE2B;
    }
  }

  .tnc {
    font-size: 12px;
    color: #707070;
    text-align: center;
    padding: 10px 40px 20px 40px;
    font-weight: 100;
  }

  input {
    border: 1px solid #00000021 !important;
    border-radius: 3px !important;
  }
    .subtitle{
    color: #707070;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    vertical-align: text-top;
    margin-left:2px;
  }
`