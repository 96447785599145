import styled from "@emotion/styled"
import theme from "@styles/theme"
import { Legal } from "@styles/Global.styles"
import mq from "@styles/mq"
import React from "react"

export const GemMessage = styled.div`
  img {
    ${mq({
      width: ['40px', '59px', '59px'],
      marginBottom: ['-9px', '-9px', '-9px'],
      marginLeft: ['4px', '9px', '9px'],
      textAlign: ['center !important', 'center !important', 'center !important']
    })}
  }

  ${mq({
    marginBottom: ['10px', '10px', '-4px', '-4px'],
    fontSize: ['14px !important', '14px !important', '14px !important', '14px !important']
  })}
`

export const APMessage = styled(({isMobile,...others})=><div {...others} />)`
  afterpay-placement {
    display: flex;
    width: 10px !important
    svg {
      width: 10px !important
    }
  }

  slot {
    font-weight: bold !important;
  }

  .afterpay-logo-badge-background{

  }

  --messaging-font-size: ${props=>props.isMobile  === true ? "14px" : "14px"};
  ${mq({
    marginLeft: ['-5px', '-5px', '0px', '0px']
  })}
`

export const PopUp = styled.div`
  display: inline;
  font-size: 12px;
  margin-left: 5px;
  cursor: pointer;
`