import styled from "@emotion/styled";
import { Menu } from "semantic-ui-react";
import React from "react";
import theme from "@styles/theme";

export const StickyTopMenu = styled(({visible, darkTheme, ...others})=><Menu {...others} />)`
    display: ${props=>props.visible  === true ? "block !important" : "none !important"};
    padding: 20px 0 20px 0;
    background-color: ${p => (p.darkTheme ? 'black !important' : 'white')};
    color: ${p => (p.darkTheme ? 'white' : 'black')};
`

export const Header = styled.div`
    padding-right:10px;
    padding-left:10px;
    padding-top:20px;
`;

export const HeaderStep = styled.div`
    color: ${theme.brand.colors.white};
    text-align: right;
    font-weight: bold;
`;

export const HeaderDesktop = styled.div`
    background-color: black;
    color: white;
    margin-bottom:32px;
`;