import { AfterpayMessage } from "@utils/Afterpay"
import React from "react"
import { Grid } from "semantic-ui-react"
import { APMessage, PopUp, GemMessage } from "./CombinationPaymentList.styles"
import { CombinationPaymentListPopup } from "./CombinationPaymentListPopup"
import { graphql, useStaticQuery } from "gatsby"
import { getCurrentBasket } from "@redux/localStorage/basket"
import { isMobile } from "@utils/Helpers"
import { checkPromoService } from "@services/BasketService"
import { getAllowAfterToLocalStorage } from "@redux/localStorage/checkout"

interface CombinationPaymentListProps {
  basePrice: number
  isMobile?: boolean | false
  isCenter?: boolean | false
}

export const CombinationPaymentList = (
  props: CombinationPaymentListProps
) => {

  const { basePrice, isCenter } = props
  let basket = getCurrentBasket()
  let isAllowedAfterPay = getAllowAfterToLocalStorage()
  var isDirectMobile =  isMobile()

  return (
    <div style={{marginTop: '8px'}}>
      <Grid.Column width={16}>
          <GemMessage >
            or 6 months interest-free with Gem Visa
           <img width="37" src="https://assets-au-01.kc-usercontent.com/b4867b92-df10-028b-fef2-a66cd00381c7/3366a377-06e7-45af-85ef-1ba3443400f7/gem.png"/>
          </GemMessage>
      </Grid.Column>
      <Grid.Column width={16}>
          <APMessage style={{display: isCenter ? 'inline-block': ''}} isMobile={isDirectMobile}>
            {((basket.summary.promotionCode === undefined || basket.summary.promotionCode === '') || isAllowedAfterPay === 'true') && <AfterpayMessage amount={basePrice} size="xs" isMobile={isDirectMobile}/>}
          </APMessage>
      </Grid.Column>
    </div>
  )
}