import styled from "@emotion/styled";
import theme from "@styles/theme";
import mq from '@styles/mq';
import { H5, Paragraph2, Legal } from "@styles/Global.styles";
import { Grid, GridColumn } from "semantic-ui-react";

export const CombinationPricingOptionsContainer = styled.div`
    background-color: ${theme.brand.colors.white};
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.12);
    text-align: left;
    ${mq({
        padding: ['20px 20px 15px 20px', '30px 30px 15px 30px', '30px 30px 15px 30px']
    })}
`
export const CombinationPricingOptionsHeading = styled(H5)`
    ${mq({
        color: ['white', 'black', 'black'],
        fontSize:['16px !important;','18px !important;','18px !important;'],
        marginTop:['-6px', '0px', '0px'],
        marginLeft:['8px', '0px', '0px'],
        marginBottom:['6px', '20px', '20px']
    })}
`

export const CombinationPricingOptionContainer = styled.div`
    ${mq({
        borderTop: ['', '2px solid #707070', '2px solid #707070'],    
        padding:['0px 0px','20px 0px;','20px 0px;']    
    })}
    .price-options-divider{
        width:100%;
        height: 1px;
        margin-top:16px;
        margin-bottom:0px;
    }
`

export const SelectedCombinationPricingOptionContainer = styled.div`
    background-color: ${theme.brand.colors.lightToGrey};
    padding: 8px;
    margin: 0px 0px 5px 0px;

    > .grid {
        margin-right: 0 !important;
    }


`

export const CombinationPricingOptionsPrice = styled.div`
    &:first-of-type {
        float:right;
    }

    > span {
        color: ${props => props.color};
        ${mq({
            fontSize: ['14px', '16px', '16px']
        })}
        line-height: 28px;
        margin-left: 3px;
        font-weight:700;
    }

    > label {
        color: ${theme.brand.colors.darkGrey};
        font-weight: 100;
        font-size: 12px;
        letter-spacing: -0.26px;
        ${mq({
            verticalAlign: ['middle', 'super', 'super']
        })}
    }

    display: inline-block;
`

export const CombinationPricingOptionsDescription = styled(Paragraph2)`
    margin-bottom: 0 !important;
    color: ${theme.brand.colors.black};

    .other-description {
        color: ${theme.brand.colors.darkGrey};
    }
`

export const CombinationPricingOptionsImage = styled.div`
    border-radius: 4px;
    border: solid 3px ${theme.brand.colors.darkGrey};
    display: inline-block;
    width: 112px;
    margin-right: 10px;
    text-align: center;
    margin-top: 5px;
    margin-left: 0;

    > span {
        font-family: 'NZ Plate Webfont';
        font-size: 21px;
        color: ${theme.brand.colors.darkGrey};
    }
`

export const CombinationPricingOptionsExampleText = styled(Legal)`
    display: block;
    margin-top: 7px;
`

export const CombinationPricingOptionsPriceContainer = styled(GridColumn)`
    ${mq({
        textAlign: ['right !important;', 'left !important;', 'left !important;']
    })}
    padding-top:0px !important;
    padding-bottom:0px !important;
`
export const CombinationPricingDisclaimer = styled.div`
    font-size: 10px;
    color: ${theme.brand.colors.darkGrey};
    ${mq({
        marginTop: ['15px', '0px', '0px']
    })}
`
export const CombinationPricingOptionsOuterWrapper = styled.div`
    .outer-wrapper{
        background-color: #fff;
        border-radius: 5px;        
        margin-left:2px;
        margin-right:2px;
    }
    .accordion-title{
        font-size: 16px !important;
        padding: 15px 24px !important;
        
    }
    .accordion-title > i{
        float:right;
    }
    .accordion-content{
        padding: 24px 24px !important;
    }
    .accordion-title.active{
    border-bottom: 8px solid black;
    }
`

export const LeftColumn = styled(Grid.Column)`
    padding-top:0px  !important;
    padding-bottom:0px  !important;
`
export const RestyleCombinationPricingOptionsHeading = styled(H5)`
    ${mq({
        color: ['black', 'black', 'black'],
        fontSize:['16px !important;','18px !important;','18px !important;'],
        marginTop:['-6px', '0px', '0px'],
        marginLeft:['0px', '0px', '0px'],
        marginBottom:['6px', '20px', '20px']
    })}
`
export const RestyleCombinationPricingOptionContainer = styled.div`
    ${mq({
        borderTop: ['2px solid #707070', '2px solid #707070', '2px solid #707070'],    
        padding:['12px 0px','20px 0px;','20px 0px;']    
    })}
    .price-options-divider{
        width:100%;
        height: 1px;
        margin-top:16px;
        margin-bottom:0px;
    }
`