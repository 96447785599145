import React from 'react'
import { PlateFormat } from '@models/index';
import { Accordion, AccordionContent, AccordionTitle, Divider, Grid, Icon, Responsive } from 'semantic-ui-react';
import { CombinationPricingOptionsContainer, CombinationPricingOptionsHeading, CombinationPricingOptionContainer, CombinationPricingOptionsImage, CombinationPricingOptionsPrice, CombinationPricingOptionsDescription, SelectedCombinationPricingOptionContainer, CombinationPricingOptionsExampleText, CombinationPricingOptionsPriceContainer, CombinationPricingDisclaimer, CombinationPricingOptionsOuterWrapper, LeftColumn, RestyleCombinationPricingOptionsHeading, RestyleCombinationPricingOptionContainer } from './CombinationPricingOptions.styles';
import { H6} from '@styles/Global.styles';
import theme from '@styles/theme';
import { getPlateFormatExampleCombinations, getPlateFormat } from '@utils/Helpers';


interface CombinationPricingOptionsProps {
    combinationFormats: PlateFormat[],
    darkTheme: boolean
}

interface CombinationPricingOptionsState {
    selectedCombinationFormatId?: number,
    activeIndex: number
}

/* eslint-disable jsx-a11y/label-has-associated-control */
class RestyleCombinationPricingOptions extends React.Component<CombinationPricingOptionsProps,CombinationPricingOptionsState>{

    constructor(props:CombinationPricingOptionsProps){
        super(props);
        this.state = {
            selectedCombinationFormatId: undefined,
            activeIndex: -1
        }
    }

    selectCombinationFormat = (formatType: string) => {
        this.props.combinationFormats.forEach((plateFormat, index) => {
            if(formatType.includes(plateFormat.formatType.slice(0, plateFormat.formatType.indexOf(" ")))){
                this.setState({
                    selectedCombinationFormatId: index
                })
            }
        });
    }

    clearSelectCombinationFormat = () => {
        this.setState({
            selectedCombinationFormatId: undefined
        })
    }
    
    handleClick = (e : any, titleProps : any) => {
        const { index } = titleProps
        const newIndex = this.state.activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render(){
        const { selectedCombinationFormatId } = this.state;
        const { combinationFormats, darkTheme } = this.props;
        return (
            <CombinationPricingOptionsOuterWrapper>
                <Responsive minWidth={theme.responsive.tablet.minWidth} >
                    <CombinationPricingOptionsContainer>
                        <CombinationPricingOptionsHeading>
                            Restyle Guide
                        </CombinationPricingOptionsHeading>
                        {
                            selectedCombinationFormatId !== undefined &&
                            <CombinationPricingOptionContainer>
                                <CombinationPricingOptionsDescription>
                                    You are Restyling:
                                </CombinationPricingOptionsDescription>
                                <SelectedCombinationPricingOptionContainer>
                                    <Grid columns={2}>
                                        <Grid.Row>
                                            <Grid.Column mobile={8} tablet={8} computer={8} largeScreen={7} widescreen={7}>
                                                <H6>
                                                    {combinationFormats[selectedCombinationFormatId].name}
                                                </H6>
                                            </Grid.Column>
                                            <Grid.Column mobile={8} tablet={8} computer={8} largeScreen={9} widescreen={9} textAlign='right'>
                                                <CombinationPricingOptionsPrice color={theme.brand.colors.green}>
                                                    <label>
                                                        From
                                                    </label>
                                                    <span>
                                                    ${combinationFormats[selectedCombinationFormatId].basePrice}
                                                    </span>
                                                </CombinationPricingOptionsPrice>
                                                
                                            </Grid.Column>
                                            <Grid.Column width={16}>
                                                <CombinationPricingOptionsDescription>
                                                    {combinationFormats[selectedCombinationFormatId].description}
                                                </CombinationPricingOptionsDescription>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </SelectedCombinationPricingOptionContainer>
                            </CombinationPricingOptionContainer>
                        }
                        {
                            combinationFormats.map((combinationFormat, index) => {
                                const laybyFromPrice = (combinationFormat.basePrice / 12);
                                return (
                                    selectedCombinationFormatId !== index && selectedCombinationFormatId === undefined &&
                                    <CombinationPricingOptionContainer key={index}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column mobile={8} tablet={8} computer={8} largeScreen={8} widescreen={8}>
                                                    <H6>
                                                        {combinationFormat.name}
                                                    </H6>
                                                </Grid.Column>
                                                <Grid.Column mobile={8} tablet={8} computer={8} largeScreen={8} widescreen={8} textAlign='right'>
                                                    <CombinationPricingOptionsPrice color={theme.brand.colors.green}>
                                                        <label>
                                                            From
                                                        </label>
                                                        <span>
                                                            ${combinationFormat.basePrice}
                                                        </span>
                                                    </CombinationPricingOptionsPrice>
                                                    
                                                </Grid.Column>
                                                <Grid.Column width={16}>
                                                    <CombinationPricingOptionsDescription>
                                                        {combinationFormat.description}
                                                    </CombinationPricingOptionsDescription>
                                                </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </CombinationPricingOptionContainer>
                                    )
                                })
                        }
                    </CombinationPricingOptionsContainer>
                </Responsive>
                {darkTheme ? 
                <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                    <CombinationPricingOptionsHeading>
                        {selectedCombinationFormatId === undefined ? 'Redesign Guide' : 
                    <Grid columns={2}>                    
                        <LeftColumn width={10}>
                            <H6>
                                {combinationFormats[selectedCombinationFormatId].name}
                            </H6>
                        </LeftColumn>
                        <CombinationPricingOptionsPriceContainer width={6} >
                            <CombinationPricingOptionsPrice color={theme.brand.colors.green}>
                                <label color='#eee'>
                                    From
                                </label>
                                <span>
                                    ${combinationFormats[selectedCombinationFormatId].basePrice}
                                </span>
                            </CombinationPricingOptionsPrice>
                        </CombinationPricingOptionsPriceContainer>
                    </Grid>
                    }
                    </CombinationPricingOptionsHeading>
                    <Accordion className='outer-wrapper' >
                        <AccordionTitle className='accordion-title'
                        active={this.state.activeIndex === 0}
                        index={0}
                        onClick={this.handleClick} 
                        >                        
                        View format prices {this.state.activeIndex === 0 ? <Icon name='chevron up'/> : <Icon name='chevron down'/>} 
                        </AccordionTitle>
                        <AccordionContent active={this.state.activeIndex === 0} className='accordion-content'>
                        {
                            selectedCombinationFormatId !== undefined &&
                            <CombinationPricingOptionContainer>
                                <SelectedCombinationPricingOptionContainer>
                                    <Grid columns={2}>
                                        <Grid.Row>
                                            <Grid.Column mobile={12} tablet={8} computer={8} largeScreen={7} widescreen={7}>
                                                <H6>
                                                    {combinationFormats[selectedCombinationFormatId].name}
                                                </H6>
                                            </Grid.Column>
                                            <Grid.Column mobile={4} tablet={8} computer={8} largeScreen={9} widescreen={9} textAlign='right'>
                                                <CombinationPricingOptionsPrice color={theme.brand.colors.green}>
                                                    <label>
                                                        From
                                                    </label>
                                                    <span>
                                                    ${combinationFormats[selectedCombinationFormatId].basePrice}
                                                    </span>
                                                </CombinationPricingOptionsPrice>
                                                
                                            </Grid.Column>
                                            <Grid.Column width={16}>
                                                <CombinationPricingOptionsDescription>
                                                    {combinationFormats[selectedCombinationFormatId].description}
                                                </CombinationPricingOptionsDescription>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </SelectedCombinationPricingOptionContainer>
                            </CombinationPricingOptionContainer>
                        }
                        {
                            combinationFormats.map((combinationFormat, index) => {
                                const laybyFromPrice = (combinationFormat.basePrice / 12);
                                return (
                                    selectedCombinationFormatId !== index && selectedCombinationFormatId === undefined &&
                                    <CombinationPricingOptionContainer key={index}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column mobile={12} tablet={8} computer={8} largeScreen={8} widescreen={8}>
                                                    <H6>
                                                        {combinationFormat.name}
                                                    </H6>
                                                </Grid.Column>
                                                <Grid.Column mobile={4} tablet={8} computer={8} largeScreen={8} widescreen={8} textAlign='right'>
                                                    <CombinationPricingOptionsPrice color={theme.brand.colors.green}>
                                                        <label>
                                                            From
                                                        </label>
                                                        <span>
                                                            ${combinationFormat.basePrice}
                                                        </span>
                                                    </CombinationPricingOptionsPrice>
                                                    
                                                </Grid.Column>
                                                <Grid.Column width={16}>
                                                    <CombinationPricingOptionsDescription>
                                                        {combinationFormat.description}
                                                    </CombinationPricingOptionsDescription>
                                                </Grid.Column>
                                                {index < 1 && <Divider clearing className='price-options-divider'/> }
                                            </Grid.Row>
                                        </Grid>
                                    </CombinationPricingOptionContainer>
                                    )
                                })
                        }
                        </AccordionContent>
                    </Accordion>
                </Responsive> : 
                <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                    
                    <RestyleCombinationPricingOptionsHeading>
                        Restyle Guide
                    </RestyleCombinationPricingOptionsHeading>
                {
                    selectedCombinationFormatId !== undefined &&
                    <RestyleCombinationPricingOptionContainer>
                        <CombinationPricingOptionsDescription>
                            You are Restyling:
                        </CombinationPricingOptionsDescription>
                        <SelectedCombinationPricingOptionContainer>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={16} computer={7} largeScreen={7} widescreen={7}>
                                        <H6>
                                            {combinationFormats[selectedCombinationFormatId].name}
                                        </H6>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={9} widescreen={9} textAlign='right'>
                                        <H6>
                                            From ${combinationFormats[selectedCombinationFormatId].basePrice}
                                        </H6>
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <CombinationPricingOptionsDescription>
                                            {combinationFormats[selectedCombinationFormatId].description}
                                        </CombinationPricingOptionsDescription>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </SelectedCombinationPricingOptionContainer>
                    </RestyleCombinationPricingOptionContainer>
                }
                {
                    combinationFormats.map((combinationFormat, index) => {
                        const laybyFromPrice = (combinationFormat.basePrice / 12);
                        return (
                            selectedCombinationFormatId !== index && selectedCombinationFormatId === undefined &&
                            <RestyleCombinationPricingOptionContainer key={index}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column mobile={16} tablet={16} computer={7} largeScreen={8} widescreen={8}>
                                            <H6>
                                                {combinationFormat.name}
                                            </H6>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={8} widescreen={8} textAlign='right'>
                                            <H6>
                                                From ${combinationFormat.basePrice}
                                            </H6>
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <CombinationPricingOptionsDescription>
                                                {combinationFormat.description}
                                            </CombinationPricingOptionsDescription>
                                        </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </RestyleCombinationPricingOptionContainer>
                            )
                        })
                    }
                
                </Responsive>
                }
            </CombinationPricingOptionsOuterWrapper>
        )
    }
}

export default RestyleCombinationPricingOptions;