import { LeadGenStatus, LeadGenType, LeadSource } from '@utils/Constant';
import Axios from 'axios'

const reserveNotifyCombination = async (apiUrl:string, postData:any) => {
    const url = `${apiUrl}/leadgen`;
    const result = await Axios.post(url, postData);
    const data = result.data;
    if(data.Success){
        return data.Data;
    }
}

const getCombination = async (apiUrl:string, lid:string, email:string) => {
    const url = `${apiUrl}/leadgen?lid=${lid}&email=${email}`;
    const result = await Axios.get(url);
    const data = result.data;
    if(data.Success){
        return data.Data;
    }
}

const getCombinationByEmail = async (apiUrl:string, email:string) => {
    const url = `${apiUrl}/leadgen?lid=0&email=${email}`;
    const result = await Axios.get(url);
    return result.data;
}

export const LeadService = {
    reserveNotifyCombination : reserveNotifyCombination,
    getCombination: getCombination,
    getCombinationByEmail: getCombinationByEmail
}